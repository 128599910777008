<template>
  <div class="duplicateHistoryLines">
    <Modal
      v-if="modal"
      class="duplicateHistoryLines__modal"
      @close="modal = false"
    >
      <template #header>
        <div class="header">
          <p class="title">
            Itens duplicados
          </p>
        </div>
      </template>

      <template #body>
        <p v-if="duplicateHistoryLines.data.content.length" class="disclaimer">
          Esta tela contém os itens duplicados na quinzena. Você pode manter todos ou excluir um dos itens abaixo:
        </p>
        <div class="table">
          <DataTable
            :header="duplicateHistoryLines.data.headers"
            :table="duplicateHistoryLines.data.content"
            @remove="onConfirmDeleteFile"
          />
          <div class="footer">
            <div class="buttons">
              <BaseButton
                class="button"
                label="Cancelar"
                color="dark"
                filled
                text-color="white"
                @click="modal = false"
              />

              <BaseButton
                class="button"
                label="Manter todos"
                color="dark"
                filled
                text-color="white"
                :disabled="!hasDuplicateItems"
                @click="keepAllLines"
              />
            </div>
          </div>
        </div>
      </template>
    </Modal>

    <Dialog
      v-if="confirmDeleteDialog"
      class="duplicateHistoryLines__dialog"
      warning="Isso vai excluir o motorista do upload para o pagamento, tem certeza que vai apagar?"
      label-cancel="Cancelar"
      label-confirm="Confirmar"
      :listener-keyup="false"
      @cancel="confirmDeleteDialog = false"
      @confirm="deleteLine"
    />
  </div>
</template>

<script>
import { financial } from '@/api'
import { mapState, mapActions } from 'vuex'
import { DataTable, Modal, Dialog } from '@/components/molecules'
import { BaseButton } from '@/components/atoms'

export default {
  name: 'DialogDuplicateLines',
  
  components: {
    BaseButton,
    DataTable,
    Modal,
    Dialog
  },

  data() {
    return {
      modal: false,
      mainLine: null,
      confirmDeleteDialog: false,
      lineBeingDeleted: null
    }
  },

  computed: {
    ...mapState({
      duplicateHistoryLines: state => state.financial.duplicateHistoryLines
    }),

    hasDuplicateItems() {
      return !!this.duplicateHistoryLines.data.content.length
    }
  },

  methods: {
    ...mapActions({
      setLoading: 'loading/setLoading',
      fetchDuplicateHistoryLines: 'financial/fetchDuplicateHistoryLines',
    }),

    open() {
      this.modal = true
      this.$emit('open', this.modal)
    },

    onConfirmDeleteFile(line) {
      this.lineBeingDeleted = line.id
      this.confirmDeleteDialog = true
    },

    async deleteLine() {
      this.setLoading(true)
      await financial.deleteuploadHistoryLine(this.lineBeingDeleted, (res) => {
        this.fetchDuplicateItem(this.mainLine)
        this.modal = false
        this.handlerEmitFetchDuplicatesHistory()
        this.$toast.success(res?.message, { position: 'bottom-center' })
      }, (e) => e, () => this.setLoading(false))
    },

    async fetchDuplicateItem(id) {
      this.mainLine = id
      await this.fetchDuplicateHistoryLines(id).then(() => {
        this.open()
      })
    },

    handlerConfirmDeleteLineModal(line) {
      this.$refs.confirmDeleteLineModal?.handlerModal(line.id, this.mainLine)
    },

    handlerEmitFetchDuplicatesHistory() {
      this.$emit('fetchEditUploadedHistory')
    },

    async keepAllLines() {
      this.setLoading(true)
      const body = {
        costs_id: this.duplicateHistoryLines.data.content.map(el => el.id)
      }
      await financial.keepAllDuplicateLines(body, (res) => {
        this.$toast.success(res?.message, { position: 'bottom-center' })
        this.modal = false
        this.handlerEmitFetchDuplicatesHistory()
      }, (e) => e, () => this.setLoading(false))
    },
  }
}
</script>

<style lang="scss" scoped>
.duplicateHistoryLines {
  &__modal {
    /deep/ .Modal__container {
      width: 850px;
      height: auto;

      .header {
        text-align: center;

        .title {
          padding: 7px;
        }
      }

      .disclaimer {
        padding: 10px 20px;
      }

      .table {
        padding: 10px; 

        .footer {
          display: flex;
          justify-content: flex-end;

          .buttons {
            display: flex;

            .button {
              margin: 10px;
              height: 25px;
              width: 110px;
              
              span {
                text-transform: none;
                margin-left: 0;
              }
            }
          }
        }
      }
    }
  }

  &__dialog {
    .Dialog__Texts {
      width: 100%;
      margin-top: 0;

      .Dialog__Warning {
        padding: 10px 20px;
      }
    }
  }
}
</style>
<template>
  <div class="Financial">
    <TheSubHeader
      :actions="['back']"
      type="filter"
      :title="getSpreadsheetName"
      page-title-tag="Financial Edit Uploaded History"
      organizer-gap="1rem"
      organizer-columns="1fr 300px 1fr"
      icon="money"
    >
      <template #filter>
        <!-- Drivers filter -->
        <BaseTreeSelect
          v-model="filters.drivers"
          name="Motorista"
          label="Motorista / Beneficiário"
          placeholder="Pesquisar"
          multiple
          suggestions="searchDrivers"
          @input="updateTags(), handlerEditUploadHistoryRequest()"
        />

        <!-- stores filter -->
        <BaseTreeSelect
          v-model="filters.stores"
          name="Loja"
          label="Loja"
          placeholder="Selecione"
          multiple
          :enable-paste="false"
          :select-options="null"
          :fetch-options="'stores/fetchStoresAsync'"
          @input="updateTags(), handlerEditUploadHistoryRequest()"
        />
      </template>

      <!-- filters tag -->
      <template v-if="allTags.length > 0" #tag>
        <FiltersTag :tags="allTags" @removeTag="removeTag" @clear="clearAllTags" />
      </template>
    </TheSubHeader>
    
    <!-- Content edit upload history component -->
    <section class="Financial__Content">
      <DataTable
        :header="uploadHistoryEdit.data.headers"
        :table="uploadHistoryEdit.data.content"
        @remove="onConfirmDeleteFile"
        @duplicate="handlerDuplicateItensModal"
      />
      <Pagination @page="fetchEditUploadedHistory" @paginate="fetchEditUploadedHistory" />

      <!-- Duplicate items modal -->
      <DialogDuplicateLines ref="duplicateModal" @fetchEditUploadedHistory="fetchEditUploadedHistory" />

      <Dialog
        v-if="confirmDeleteDialog"
        class="Financial__dialog"
        warning="Isso vai excluir o motorista do upload para o pagamento, tem certeza que vai apagar?"
        label-cancel="Cancelar"
        label-confirm="Confirmar"
        :listener-keyup="false"
        @cancel="confirmDeleteDialog = false"
        @confirm="deleteLine"
      />
    </section>
  </div>
</template>
<script>
import { financial } from '@/api'
import { TheSubHeader } from '@/components/organisms'
import { mapState, mapGetters, mapActions } from 'vuex'
import { BaseTreeSelect } from '@/components/atoms'
import { DataTable, Pagination, FiltersTag, Dialog } from '@/components/molecules'
import DialogDuplicateLines from '@/views/financial/EditUploadedHistory/DialogDuplicateLines'

export default {
  name: 'EditUploadedHistory',

  components: {
    TheSubHeader,
    BaseTreeSelect,
    FiltersTag,
    DataTable, 
    Pagination,
    DialogDuplicateLines,
    Dialog
  },
  
  data() {
    return {
      allTags: [],
      filters: {
        drivers: [],
        stores: []
      },
      mainLine: null,
      lineBeingDeleted: null, 
      confirmDeleteDialog: false
    }
  },
  computed: {
    ...mapGetters({
      isMobile: 'userAgent/isMobile',
    }),
    
    ...mapState({
      uploadHistoryEdit: state => state.financial.uploadHistoryEdit,
      page: state => state.pagination.page,
      paginate: state => state.pagination.paginate
    }),

    getSpreadsheetName() {
      if(this.uploadHistoryEdit.data.content.length) {
        const name = this.uploadHistoryEdit.data.content.find(el => el['file.file_name'])
        if(this.isMobile && name['file.file_name'].length >= 25) {
          return name['file.file_name'].substring(0, 25) + '...'
        }
        return name['file.file_name']
      }
      return ''
    }
  },

  mounted() {
    this.clearPagination(20)
    this.fetchEditUploadedHistory()
  },

  methods: {
    ...mapActions({
      clearPagination: 'pagination/clearPagination',
      setLoading: 'loading/setLoading',
      setPagination: 'pagination/setPagination',
      fetchUploadHistoryEdit: 'financial/fetchUploadHistoryEdit'
    }),

    handlerEditUploadHistoryRequest() {
      this.clearPagination(20).then(() => this.fetchEditUploadedHistory())
    },

    handlerDuplicateItensModal(item) {
      this.mainLine = item.id
      this.$refs.duplicateModal?.fetchDuplicateItem(item.id)
    },

    async fetchEditUploadedHistory() {
      const params = {
        filters: {
          beneficiary_id: this.filters?.drivers,
          store_id: this.filters?.stores,
        },
        id: this.$route.params.id || 0,
        page: this.page,
        paginate: this.paginate
      }
      await this.fetchUploadHistoryEdit(params)
      this.setPagination(this.uploadHistoryEdit)
    },

    onConfirmDeleteFile(line) {
      this.lineBeingDeleted = line.id
      this.confirmDeleteDialog = true
    },

    async deleteLine() {
      this.setLoading(true)
      await financial.deleteuploadHistoryLine(this.lineBeingDeleted, (res) => {
        this.fetchEditUploadedHistory()
        this.confirmDeleteDialog = false
        this.$toast.success(res?.message, { position: 'bottom-center' })
      }, (e) => e, () => this.setLoading(false))
    },

    updateTags(){
      this.allTags = [...this.filters.stores, ...this.filters.drivers]
    },

    removeTag(name, id){
      this.allTags = this.allTags.filter((tag) => tag.id !== id)
      this.filters.stores = this.filters.stores.filter((tag) => tag.name !== name)
      this.filters.drivers = this.filters.drivers.filter((tag) => tag.name !== name)
      this.handlerEditUploadHistoryRequest()
    },

    clearAllTags(){
      this.allTags = []
      this.filters.stores = []
      this.filters.drivers = []
      this.handlerEditUploadHistoryRequest()
    }
  },
}
</script>
<style lang="scss" scoped>
.Financial {
  &__Content {
    display: flex;
    margin: 0 auto;
    max-width: 1180px;
    width: 100%;
    max-width: 1180px;
    flex-direction: column;
    padding: 0 0 3rem 0;
    @media (max-width: $viewport-lg) {
      padding: 0 1rem 3rem 1rem;
    }
  }

  &__dialog {
    .Dialog__Texts {
      width: 100%;
      margin-top: 0;

      .Dialog__Warning {
        padding: 10px 20px;
      }
    }
  }
}
</style>